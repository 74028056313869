import "babel-polyfill";
import { BeaconWallet} from "@taquito/beacon-wallet";
import { TezosToolkit, TezosOperationError, OpKind} from "@taquito/taquito";
import { NetworkType, SigningType, RequestSignPayloadInput} from "@airgap/beacon-sdk";
import { char2Bytes } from '@taquito/utils';

let userAddress, userBalance;

kv.isDev = false;//window.location.href.includes('localhost');



kv.artContractAddress = kv.isDev ? 'KT1K6rrJjBzBRWenbHvcqFuQco2XCRbkZbNA' : 'KT1XnSjWtLQyR9CRAoSpAMiSKBvHT5teEQoN';
kv.marketContractAddress = kv.isDev ? 'KT1SipRru489A6Mtapvs46YJe3cdMhrmWjdg' : 'KT1TogkH3SiXwcg3YPDtMQG896fi6tTmzSTT';
kv.propertyContractAddress = kv.isDev ? 'KT1VniXFa27Gj1zEEh6nbkmHmvFBC6MHPGLk' : 'KT1TpRsnNWswaWFxdFZTTWRgh4cnijjxYqdS';
kv.adminAddress = 'tz1fj3ymeFEVJNEvPKm8BTHxXzXjjfJTkgzu';

const MINT_FILESIZE = 100

const MIMETYPE = {
  BMP: 'image/bmp',
  GIF: 'image/gif',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  SVG: 'image/svg+xml',
  TIFF: 'image/tiff',
  WEBP: 'image/webp',
  MP4: 'video/mp4',
  OGV: 'video/ogg',
  QUICKTIME: 'video/quicktime',
  WEBM: 'video/webm',
  GLB: 'model/gltf-binary',
  GLTF: 'model/gltf+json',
  MP3: 'audio/mpeg',
  OGA: 'audio/ogg',
  WAV: 'audio/wav',
  XWAV: 'audio/x-wav',
  FLAC: 'audio/flac',
  PDF: 'application/pdf',
  ZIP: 'application/zip',
  ZIP1: 'application/x-zip-compressed',
  ZIP2: 'multipart/x-zip',
  MD: 'text/markdown',
}

kv.mimeTypes = MIMETYPE;


const ALLOWED_MIMETYPES = Object.keys(MIMETYPE)
  .map((k) => MIMETYPE[k])
  .filter((e) => e !== MIMETYPE.GLTF) // disabling GLTF from new updates

const ALLOWED_FILETYPES_LABEL = Object.entries(MIMETYPE)
  .filter((e) => ALLOWED_MIMETYPES.includes(e[1]))
  .filter(
    (e) =>
      ![
        'ZIP1',
        'ZIP2',
        'OGA',
        'OGV',
        'BMP',
        'TIFF',
        'XWAV',
        'QUICKTIME',
        'WEBP',
      ].includes(e[0])
  )
  .map((e) => (e[0] === 'ZIP' ? 'HTML (ZIP ARCHIVE)' : e[0]))
  .join(', ')

const ALLOWED_COVER_MIMETYPES = [
  MIMETYPE.JPEG,
  MIMETYPE.PNG,
  MIMETYPE.GIF,
  MIMETYPE.MP4,
]

const ALLOWED_COVER_FILETYPES_LABEL = ['jpeg, png, gif']

const MAX_EDITIONS = 10000 // Limited by contract

const MIN_ROYALTIES = 10 // Limited by contract

const MAX_ROYALTIES = 25 // Limited by contract

const IPFS_DEFAULT_THUMBNAIL_URI =
  'ipfs://QmNrhZHUaEqxhyLfqoq1mtHSipkWHeT31LNHb1QEbDHgnc'

const BURN_ADDRESS =  'tz1burnburnburnburnburnburnburjAYjjX'
//'tz1burnburnburnburnburnburnkiloverse'


//type: NetworkType.MAINNET,
//rpcUrl: "https://mainnet-tezos.giganode.io/",

var options = {
  name: "Kiloverse.io",
  preferredNetwork: kv.isDev ?  NetworkType.ITHACANET : NetworkType.MAINNET
};

if(!kv.isDev){

  options = {
    type: NetworkType.MAINNET,
    rpcUrl: "https://mainnet.api.tez.ie/",
  };

}



//	https://mainnet.api.tez.ie

/*
network: {
    type: NetworkType.MAINNET,
    rpcUrl: "https://mainnet-tezos.giganode.io/",
  }
  */

options = {name:'Kiloverse'};
var net = kv.isDev ? 'https://ithacanet.smartpy.io/' : 'https://mainnet.api.tez.ie';
//'https://mainnet.api.tez.ie/';

//net = ;
const wallet = new BeaconWallet(options);
kv.wallet = wallet;


window.tk = new TezosToolkit(net);
window.tk.setWalletProvider(wallet);






/*
/*
eventHandlers: {
PERMISSION_REQUEST_SUCCESS: {
handler: async data => {
  console.log("Wallet is connected:", data);
},
},
OPERATION_REQUEST_SENT: {
handler: async data => {
  console.log("Request sent:", data);
},
},
OPERATION_REQUEST_SUCCESS: {
handler: async data => {
  console.log("Request successful:", data);
  showToast("Request successful!");
},
},
OPERATION_REQUEST_ERROR: {
handler: async data => {
  console.log("Request error:", data);
  showToast("Request error!");
},
},
}*/


const connectWallet = async () => {
  try {


    // setting up network
    const network = {
      type:  kv.isDev ?  NetworkType.ITHACANET : NetworkType.MAINNET,
      rpcUrl: kv.isDev ? 'https://ithacanet.smartpy.io/' : 'https://mainnet.api.tez.ie',
    };


    // requesting permissions on selected network
    await wallet.requestPermissions({ network });

    const userAddress = await wallet.getPKH();
    window.address = userAddress;
    console.log("Your address:", userAddress);
    // getting user's balance on Carthagenet
    kv.exports.userBalance = await window.tk.tz.getBalance(userAddress);

    kv.updateLayout();

  } catch (error) {

    console.log(error);

  }

};

kv.connectWallet = connectWallet;


kv.burn = async (item, quantity) => {

  kv.activeAccount = await wallet.client.getActiveAccount();
  if(!kv.activeAccount){
    kv.connectWallet();
    return;
  }

  console.log('omg'  + item.contract);

  const tokenContractInstance = await window.tk.wallet.at(item.contract);
  tokenContractInstance.methods.transfer([
    {
      from_: kv.walletAddress,
      txs: [
        {
          to_: BURN_ADDRESS,
          token_id: parseInt(item.tokenId),
          amount: parseInt(quantity)
        },
      ],
    },
  ]).send()

};



kv.cancel =  async (swap) => {


  kv.activeAccount = await wallet.client.getActiveAccount();
  if(!kv.activeAccount){
    kv.connectWallet();
    return;
  }

  const tokenContractInstance = await window.tk.wallet.at(swap.fa2);
  const marketContractInstance = await window.tk.wallet.at(kv.marketContractAddress);

  marketContractInstance.methods.cancel_swap(parseFloat(swap.swapId)).send({ amount: 0, storageLimit: 310 });

};


kv.collect = async (swap, partner) => {

  //debugger;

    debugger;

  if(!partner)partner = kv.adminAddress;
  kv.activeAccount = await wallet.client.getActiveAccount();

    if(!kv.activeAccount){
      kv.connectWallet();
      return;
    }

    const marketContractInstance = await window.tk.wallet.at(kv.marketContractAddress);

    //const tokenContractInstance = await window.tk.wallet.at(item.fa2);

    const ok =  await marketContractInstance.methods.collect(partner, parseFloat(swap.swapId)).send({
      amount: parseFloat(swap.xtz_per_kilo),
      mutez: true,
      storageLimit: 350
    });

    debugger;





}


kv.sign = async function() {

  var payload = btoa('Panera');

  const response = await wallet.client.requestSignPayload({
    signingType: SigningType.RAW,
    payload: payload,
  });

  console.log(`Signature: ${response.signature}`);

}


kv.swap = async (item, cb) => {

  kv.activeAccount = await wallet.client.getActiveAccount();
  if(!kv.activeAccount){
    kv.connectWallet();
    return;
  }


  const tokenContractInstance = await window.tk.wallet.at(item.contract);
  const marketContractInstance = await window.tk.wallet.at(kv.marketContractAddress);

  var call1 ={
    kind: OpKind.TRANSACTION,
    ...tokenContractInstance.methods.update_operators([
    {
      add_operator: {
        operator: kv.marketContractAddress,
        token_id: parseFloat(item.tokenId),
        owner: kv.activeAccount.address
      },
    }
  ]).toTransferParams({ amount: 0, mutez: true, storageLimit: 175 })
  }

  //debugger;



  var call2 = {
    kind: OpKind.TRANSACTION,
    ...marketContractInstance.methods.swap(
      item.contract,
      parseInt(item.tokenId),
      parseInt(item.editionsForSale),
      parseInt(item.xtz),
      parseInt(0),
      item.creators[0]).toTransferParams({ amount: 0, mutez: true, storageLimit: 300 })
  }

  var call3 = {
    kind: OpKind.TRANSACTION,
    ...tokenContractInstance.methods.update_operators([
      {
        remove_operator: {
          operator: kv.marketContractAddress,
          token_id: parseFloat(item.tokenId),
          owner: kv.activeAccount.address
        }
      }
    ]).toTransferParams({amount: 0, mutez: true, storageLimit: 175})
  }

    let batch = await window.tk.wallet.batch([
      call1,
      call2,
      call3
    ]);
    const operation = await batch.send();

    await operation.confirmation(3);

    alert('done!');
    //debugger;
    //alert('successfully placed item for sale!');
    item.swap = true;
    kv.api('swap', 'POST', {metadata:item}, ()=>{
      op.confirmation(1).then(() => {
        cb();
        setTimeout(()=>{

          kv.lookupSwaps(item);
        }, 5000);
        });
    });

}

kv.lookupAllSwaps = function(cb) {

  kv.api('lookupAllSwaps', 'POST', {marketplace:kv.marketContractAddress}, (r)=>{

    kv.swaps = [];
    r.forEach((s, i) => {

      kv.swaps.push({swapId:s.key, tokenId:s.value.kilo_id, ...s.value});

      //kv.swaps[s.value.kilo_id] = {...s.value, swapId:s.key};
    });

    if(cb)cb();
    //debugger;

  });
}


kv.lookupSwaps = function(item, cb) {
  item.marketplace = kv.marketContractAddress;
  kv.api('lookupSwapId', 'POST', item, (r)=>{

    cb(r);

  });
}

kv.lookupTokenId = function (item, cb) {
  kv.api('lookupTokenId', 'POST', item, (r)=>{
    if(r.length > 0){
      if(cb)cb(r[0].tokenId);
    }
  });
}

kv.batchmint = async (amount, price ,cb) => {

  kv.activeAccount = await wallet.client.getActiveAccount();
  if(!kv.activeAccount){
      kv.connectWallet();
    return;
  }



//QmNv5GhKs3a9GH7SSd6qLXFabGjAskP3VSrYEDZGXSVdt9
  var item = {
    metapath:'ipfs://QmNv5GhKs3a9GH7SSd6qLXFabGjAskP3VSrYEDZGXSVdt9',
    contract: kv.propertyContractAddress,
    editions:1
  };


  const tokenContractInstance = await window.tk.wallet.at(item.contract);
  const marketContractInstance = await window.tk.wallet.at(kv.marketContractAddress);


  var rawipfs = (item.metapath)
    .split('')
    .reduce(
      (hex, c) =>(hex += c.charCodeAt(0).toString(16).padStart(2, '0')),
      ''
    );


    var bat = [];




    for(var i =0; i < parseInt(amount); i++){
      var call ={
        kind: OpKind.TRANSACTION,
        ...marketContractInstance.methods.mint_kilo(kv.activeAccount.address,
          item.editions,
          item.contract,
          rawipfs,
          0).toTransferParams({ amount: price*1000000, mutez: true, storageLimit: 1310 })
      }
      bat.push(call);
    }

    let batch = await window.tk.wallet.batch(bat);
    const batchOp = await batch.send();

    await batchOp.confirmation();
    cb();
    alert('successfully minted kiloplots!');

};



kv.mint = async (item, cb) => {

  kv.activeAccount = await wallet.client.getActiveAccount();
  if(!kv.activeAccount){
      kv.connectWallet();
      return;
  }

  var rawipfs = (item.metapath)
    .split('')
    .reduce(
      (hex, c) =>(hex += c.charCodeAt(0).toString(16).padStart(2, '0')),
      ''
    );


try {

  var contract = await window.tk.wallet.at(kv.marketContractAddress);

  mintStatus.innerHTML = 'Submitting operation...';

  var op = await contract.methods.mint_kilo(
          kv.activeAccount.address,
          item.editions,
          item.contract,
          rawipfs,
          item.royalties).send({ amount: 0, storageLimit: 1310 });

  mintStatus.innerHTML = 'Waiting for confirmation...';

  await op.confirmation(1);

  if(cb)cb(true);

} catch (ex) {
  alert(ex);
  if(cb)cb(false);
}

}
